<template>
  <div class='indexbox'>
    <div class="searchbox">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item><a href="user">User</a></el-breadcrumb-item>
        <el-breadcrumb-item>Rule</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button class="download" type="primary" :loading='loadinga' @click="confirm">Confirm Modification</el-button>
    </div>
    <div class="tablebox" v-loading='loading'>
      <div class="bodybox">
        <div class="topbox">
          <el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event)">Select all / Deselect
            all</el-checkbox>
        </div>
        <el-tree ref="menu" :default-checked-keys='rules' :data="rulelist" default-expand-all show-checkbox node-key="id"
          :props="defaultProps"></el-tree>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      id: this.$route.query.id,
      rulelist: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      menuNodeAll: false,
      rules: [],
      loading: false,
      loadinga: false,
    };
  },
  computed: {
    ...mapGetters(['api_id']),
  },
  created() {
    this.getruleList()
  },
  methods: {
    getruleList() {
      this.loading = true
      this.$post({
        url: "/app/api_user/ruleList",
        data: {
          api_id: this.$store.state.user.api_id
        },
        success: (res) => {
          let dataa = {
            id: 100,
            title: "Assembly Order Generation",
            children: [],
          }
          let datab = {
            id: 200,
            title: "Assembly Order List",
            children: [],
          }
          let datac = {
            id: 300,
            title: "Transfer Order Import",
            children: [],
          }
          let datad = {
            id: 400,
            title: "Settings",
            children: [],
          }

          res.data.forEach(item => {
            if (item.cate == 1) {
              dataa.children.push(item)
            } else if (item.cate == 2) {
              datab.children.push(item)
            } else if (item.cate == 3) {
              datac.children.push(item)
            } else if (item.cate == 4 || item.cate == 5 || item.cate == 6 || item.cate == 7) {
              datad.children.push(item)
            }
          });
          this.rulelist = [dataa, datab, datac, datad]
          this.getuser()
          setTimeout(() => {
            this.loading = false
          }, 200);
        },
        tip: () => {
          this.loading = false
        },
      })

    },
    getuser() {
      this.$post({
        url: "/app/api_user/info",
        data: {
          api_id: this.$store.state.user.api_id,
          id: this.id,
        },
        success: (res) => {
          if (res.data.rules) {
            this.rules = res.data.rules.split(',')
          }
        },
        tip: () => {
        },
      })
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value) {
      this.$refs.menu.setCheckedNodes(value ? this.rulelist : []);
    },
    // 所有部门节点数据
    getDeptAllCheckedKeys() {
      // 目前被选中的部门节点
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半选中的部门节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    confirm() {
      this.loadinga = true
      this.loading = true
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      checkedKeys.map(item => {
        if (item > 99) {
          checkedKeys.splice(checkedKeys.indexOf(item), 1)
        }
      })
      this.$post({
        url: "/app/api_user/editRule",
        data: {
          api_id: this.$store.state.user.api_id,
          id: this.id,
          rules: checkedKeys.join(),
        },
        success: (res) => {
          this.$notify({
            title: 'Success',
            type: 'success'
          });
          this.$router.go(0)
        },
        tip: () => {
          setTimeout(() => {
            this.loading = false
            this.loadinga = false
          }, 1500);
        },
      })
    }
  },
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  height: 100%;
  .searchbox {
    width: 100%;
    padding: 20px 30px;
    background: #fff;
    border-bottom: 1px solid #babdcd;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tablebox {
    width: 100%;
    padding: 20px;
    .bodybox {
      width: 100%;
      padding: 20px 10px;
      background: #fff;
      .topbox {
        width: 100%;
        padding: 0 10px 20px;
        font-weight: 550;
      }
      /deep/.el-tree {
        >.el-tree-node {
          >.el-tree-node__content {
            font-weight: 550;
          }
        }
      }
    }
  }
}
</style>
